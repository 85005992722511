import React, { Component } from 'react';
import {CarService} from '../service/CarService';
// // import {DataTable} from 'primereact/datatable';
// // import {Column} from 'primereact/column';
// import {Panel} from 'primereact/panel';
// import {Checkbox} from 'primereact/checkbox';
// import {Button} from 'primereact/button';
// import {Dropdown} from 'primereact/dropdown';
// import {InputText} from 'primereact/inputtext';
import {Chart} from 'primereact/chart';
// import {DataTable} from 'primereact/datatable';
// import {Column} from 'primereact/column';
// import {FullCalendar} from 'primereact/fullcalendar';
// import {TodoList} from '../views/Todo/TodoList';
import {API_URL} from '../helpers/api_helpers'
import {formatMoney} from '../helpers/formats'
const axios = require('axios');

export class Dashboard extends Component {

    constructor() {
        super();
        this.state = {
            tasks: [],
            city: null,
            selectedCar: null,
            pieData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [
                            "#5CB85C",
                            "#10497F",
                            "#F0AD4E",
                            "#b90707",
                            "#e4ff00",
                            "#ff7200",
                            "#ff00e0",
                            "#0c6a00",
                        ],
                        hoverBackgroundColor: [
                            "#5CB85C",
                            "#10497F",
                            "#F0AD4E",
                            "#b90707",
                            "#e4ff00",
                            "#ff7200",
                            "#ff00e0",
                            "#0c6a00",
                        ]
                    }]
            },
        };

        this.onTaskChange = this.onTaskChange.bind(this);
        this.onCityChange = this.onCityChange.bind(this);
        this.carservice = new CarService();
    }

    onTaskChange(e) {
        let selectedTasks = [...this.state.tasks];
        if(e.checked)
            selectedTasks.push(e.value);
        else
            selectedTasks.splice(selectedTasks.indexOf(e.value), 1);

        this.setState({tasks: selectedTasks});
    }

    onCityChange(e) {
        this.setState({city: e.value});
    }

    componentWillMount() {
        this.getTrusts()
        this.getAlerts()
    }

    getTrusts(){
        var url = API_URL + "/api/v1/trusts?order=id";
        axios.get(url,{headers: {Authorization: sessionStorage.getItem('jwtToken')} } )
        .then(( res ) => {
            if (res) {
                var p = this.state.pieData
                var acounts = res.data.data.account_types
                var account_data = [];
                var account_labels = [];
                Object.keys(acounts).forEach( function(k,v){
                    account_data.push(acounts[k])
                    account_labels.push(k)
                })
                p.datasets[0]['data'] = account_data
                p.labels = account_labels
                this.setState({pieData:""})
                this.setState({trusts: res.data.trusts, pieData: p, trustData: res.data });
            }
        })
        .catch( ( error ) => {
            console.log(error);
        })
        .then( ( res ) => {
            // always executed
        });
    }

    getAlerts(){
        var url = API_URL + "/api/v1/trusts/alerts";
        axios.get(url,{headers: {Authorization: sessionStorage.getItem('jwtToken')} } )
        .then(( res ) => {
            if (res) {
                this.setState({alerts: res.data});
            }
        })
        .catch( ( error ) => {
            console.log(error);
        })
        .then( ( res ) => {
            // always executed
        });
    }

    build_category_list(){
        if (this.state.alerts) {
          var that = this
          var list = this.state.alerts.map(function(value, key){
            return (
                <div>
                    <h2 style={{color:"Red"}}>{value[0]} <a href={"/" + value[0].replace(/ /g, '').toLowerCase()} style={{float:"right"}}>{value[1]}</a> </h2>
                </div>
            );
          });
          return list
        }
    }

    render() {

        const category_list = this.build_category_list()
               
        return (
            <div className="p-grid p-fluid dashboard">
                <div className="p-col-12 p-md-12 p-lg-4">
                    <div className="card summary">
                        <div className="title">Trust 1</div>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-lg-6">
                                <span className="detail">Trust ID: 0754620.2</span>
                                <span className="detail">Number of clients: {this.state.trustData ? this.state.trustData.data.trust1[0] : ""}</span>
                                {/*<span className="detail">Funds Out: ${this.state.trustData ? this.state.trustData.data.trust1[2] : ""}</span>*/}
                                {/*<span className="detail">Funds In: ${this.state.trustData ? this.state.trustData.data.trust1[3] : ""}</span>*/}
                            </div>
                            {/*<div className="p-col-12 p-lg-6 count">*/}
                            {/*    <div className="">Balance<br/><span className="trust1 detail">{this.state.trustData ? formatMoney(this.state.trustData.data.trust1[1]) : ""}</span></div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-lg-4">
                    <div className="card summary">
                        <div className="title">Over 65</div>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-lg-6">
                                <span className="detail">Trust ID: 1706500.2</span>
                                <span className="detail">Number of clients: {this.state.trustData ? this.state.trustData.data.over65[0] : ""}</span>
                                {/*<span className="detail">Funds Out: ${this.state.trustData ? this.state.trustData.data.over65[2] : ""}</span>*/}
                                {/*<span className="detail">Funds In: ${this.state.trustData ? this.state.trustData.data.over65[3] : ""}</span>*/}
                            </div>
                            {/*<div className="p-col-12 p-lg-6 count">*/}
                            {/*    <div className="">Balance<br/><span className="over65 detail">{this.state.trustData ? formatMoney(this.state.trustData.data.over65[1]) : ""}</span></div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-lg-4">
                    <div className="card summary">
                        <div className="title">Friends & Family</div>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-lg-6">
                                <span className="detail">Trust ID: 0798020.2</span>
                                <span className="detail">Number of clients: {this.state.trustData ? this.state.trustData.data.ff[0] : ""}</span>
                                {/*<span className="detail">Funds Out: ${this.state.trustData ? this.state.trustData.data.ff[2] : ""}</span>*/}
                                {/*<span className="detail">Funds In: ${this.state.trustData ? this.state.trustData.data.ff[3] : ""}</span>*/}
                            </div>
                            {/*<div className="p-col-12 p-lg-6 count">*/}
                            {/*    <div className="">Balance<br/><span className="friends detail">{this.state.trustData ? formatMoney(this.state.trustData.data.ff[1]) : ""}</span></div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-lg-4">
                    <div className="card">
                        <h1 className="centerText">Account Types</h1>
                        <Chart type="doughnut" data={this.state.pieData} height="150" redraw={true} />
                    </div>
                </div>
                <div className="p-col-12 p-md-12 p-lg-4">
                    <div className="card">
                        <h1 className="centerText">Alerts</h1>
                        {category_list}
                    </div>
                </div>

                <div className="p-col-12 p-md-12 p-lg-4">
                    <div className="card">
                        
                    </div>
                </div>

                <div className="p-col-12 p-md-12 p-lg-12">
                    {/*<TodoList />*/}
                </div>
            </div>
        );
    }
}