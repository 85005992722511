import React, {Component} from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {InputText} from "primereact/inputtext";
// import {Password} from 'primereact/password';
import {Dropdown} from 'primereact/dropdown';
// import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {DataTable} from 'primereact/datatable';
import {ContextMenu} from 'primereact/contextmenu';
import {DataView, DataViewLayoutOptions} from 'primereact/dataview';
import {Column} from 'primereact/column';
import {Growl} from 'primereact/growl';
import {FileUpload} from 'primereact/fileupload';
import {API_URL,sleep} from '../../helpers/api_helpers'
import {Dialog} from "primereact/dialog";

const axios = require('axios');

export class Import extends Component {
	constructor(props) {
		super(props);
		this.state = {
      		imports: null,
      		contextSelection: null,
      		contextItems: [],
      		selected: [],
      		memo: "",
			menu: [
				{label: 'Transfer to Client:', icon: 'pi pi-angle-double-right', command: (event) => this.clientTransfer(event,this.state.contextSelection) },
				{separator:true },
				{label: 'Transfer to:', icon: "pi pi-angle-double-right",
					items: [
						{label: "Trust 1", command: (event) => this.trustTransfer(event,this.state.contextSelection,"WNY1000001")},
						{label: "Trust 1 Afterfund", command: (event) => this.trustTransfer(event,this.state.contextSelection,"WNY1000000")},
						{label: "Over 65", command: (event) => this.trustTransfer(event,this.state.contextSelection,"WNY2000001")},
						{label: "Over 65 Afterfund", command: (event) => this.trustTransfer(event,this.state.contextSelection,"WNY2000000")},
						{label: "Friends & Family", command: (event) => this.trustTransfer(event,this.state.contextSelection,"WNY3000001")},
						{label: "Friends & Family Afterfund", command: (event) => this.trustTransfer(event,this.state.contextSelection,"WNY3000000")},
					],
				},
				{label: 'Delete',className: 'p-button-danger', icon: 'pi pi-fw pi-trash', command: (event) =>  { if (window.confirm('Are you sure you wish to delete this item?')) this.removeRow(event,this.state.contextSelection) } },
			],
		}
		this.import_export_link = this.import_export_link.bind(this)
		this.exportAmountTemplate = this.exportAmountTemplate.bind(this)
		this.myFunction = this.myFunction.bind(this)
		this.updateClient = this.updateClient.bind(this)
		this.exportClientTemplate = this.exportClientTemplate.bind(this)
		this.exportCheckTemplate = this.exportCheckTemplate.bind(this)
		this.updateImport = this.updateImport.bind(this)
		this.delete = this.delete.bind(this)
		this.get_import_files = this.get_import_files.bind(this)
	}

	componentWillMount(){
		var permissions = JSON.parse(sessionStorage.permissions)
		if ( permissions.import === false || permissions.import === undefined ){
      window.location = "/"
    }
		this.get_import_files()
		this.get_export_files()
    this.getClients()
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	get_export_files(){
    var url = API_URL + "/api/v1/export_files/?include_records=true";
    axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem('jwtToken'),
      } 
    })
    .then(( res ) => {
      if (res) {
      	this.setState({export_files: res.data.export_files})
      	var files = []
      	res.data.export_files.map(function(k,v){
      		if (k.data_type !== 'approval'){
      			files.push({name: k.name, value: k.id})
      		}
      	})
        this.setState({files: files})
      }
    });
  }

  get_import_files(){
    var url = API_URL + "/api/v1/import_files?confirmed=false";
    axios.get(url,{
      headers: {
        Authorization: sessionStorage.getItem('jwtToken'),
      } 
    })
    .then(( res ) => {
      console.log(res)
      if (res.data.import_files.length > 0) {
        let import_files = res.data.import_files[0].records.filter(function( i, elem ){return !i.confirm;})
        res.data.import_files[0].records = import_files
        console.log(import_files)
        this.setState({imports: res.data.import_files})
      }
    });
  }

	myUploader2(event) {
   	event.xhr.setRequestHeader('Authorization', sessionStorage.getItem('jwtToken'));
    event.xhr.withCredentials = false;
	}

	import_export_link(rowData){
		if( rowData.explanation == null ){
			rowData.explanation = ""
		}
		var data = rowData.explanation.split("\\")
		if ( data == undefined ){
			return
		}
		var client_account = data[0]
		if (this.state.export_file_id === undefined ) {
			return "Please Select and Export File."
		}
		let export_file = this.state.export_files.find((e) => e.id === this.state.export_file_id.value)
		var match = ""
		export_file.records.forEach((e) => {

			var amount = parseFloat(e.data[2])
			var cash = Math.abs(parseFloat(rowData.ledger_cash))
			var explanation = rowData.explanation
			
			if (export_file.data_type === "payment" || export_file.data_type === "fee") {
				explanation = e.data[29]
			}
			if (export_file.data_type === "deposit") {
				explanation = e.data[6]
			}
			if( amount === cash && explanation.includes(client_account) ) {
				match = e.data
			}
		})
		return match
	}

	getClients(){
		var url = API_URL + "/api/v1/clients?status=active"
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var clients = res.data.clients.map((e) => {
					return {label: e.account + ": " + e.firstname + " " + e.lastname, value: e.id}
				})

				this.setState({clients: clients, client_list: res.data.clients });
			}
		});
	}

	getClient = async (account) => {
		var url = API_URL + "/api/v1/clients?account=" + account
		let res = await axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		let data = res.data
		return data		
	}

	createTransactions = async (value,client,memo) => {
		var url = API_URL + "/api/v1/transactions";
		let data = {
			client_id: client.id,
			transaction_type: value.transaction_description,
			transaction_date: value.date_traded,
			name: value.transaction_description_line2,
			memo: memo,
			split: "",
			amount: parseFloat(value.ledger_cash),
			balance:parseFloat(client.balance) + parseFloat(value.ledger_cash),
		}
		let result = await axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		let res = result.data
		return res
	}

	myFunction = async (value, index, array) =>  {
		var that = this
		var explanation = value.explanation.split(" ")[0]
		var match = this.import_export_link(value)
		if (match === "" || match === null || match === undefined){
			console.log("NO MATCH RETURNED")
			return null
		}
		value.memo = match[35]
		var url = API_URL + "/api/v1/clients?account=" + explanation
		let c = this.getClient(explanation).then( function(res){
			let client = res.clients[0]
			let d = async () => {
			  const data = await that.createTransactions(value,client,match[35])
			  return data
			}
			

			console.log(client)
			var balance = parseFloat(client.balance) + parseFloat(value.ledger_cash)
			var data = {
				client_id: client.id,
				balance: balance
			}
  		that.updateClient(data)
  		that.updateImport(value)
  		that.delete(value)
		})
	}

	exportAmountTemplate(rowData, column){
		let match = this.import_export_link(rowData)
		if (match === "Please Select and Export File." ){
			return "Please Select and Export File."
		}
		if (match === null || match === undefined || match === ""){
			return (<div>No Match Found</div>)
		} else {
			return (
				<div>
					{match[2]}
				</div>
			)
		}
	}

	exportClientTemplate(rowData, column){
		let match = this.import_export_link(rowData)
		if (match === "Please Select and Export File." ){
			return "Please Select and Export File."
		}
		if (match === null || match === undefined || match === ""){
			return (<div>No Match Found</div>)
		} else {
			let export_file = this.state.export_files.find((e) => e.id === this.state.export_file_id.value)
			
			if (export_file.data_type === "payment" || export_file.data_type === "fee") {
				var explanation = match[29]
			}
			if (export_file.data_type === "deposit") {
				explanation = match[6]
			}
			return (
				<div>
					{explanation}
				</div>
			)
		}
	}

	exportCheckTemplate(rowData, column){
		let match = this.import_export_link(rowData)
		if (match === "Please Select and Export File." ){
			return "Please Select and Export File."
		}
		if (match === null || match === undefined || match === ""){
			return (<div>No Match Found</div>)
		} else {
			let export_file = this.state.export_files.find((e) => e.id === this.state.export_file_id.value)
			
			if (export_file.data_type === "payment" || export_file.data_type === "fee") {
				var explanation = match[35]
			}
			if (export_file.data_type === "deposit") {
				explanation = "Check # " + match[10]
			}
			return (
				<div>
					{explanation}
				</div>
			)
		}
	}

	updateClient(data){
		var url = API_URL + "/api/v1/clients/" + data.client_id ;
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
			}
		});
	}

	clientTransfer(event,rowData){
		var bestGuess = rowData.explanation.split(" ")[0]
		this.setState({showClient: true, bestGuess: bestGuess, client_data: rowData})
	}

	transferClient(){
		console.log(this.state.selected_client)
		var value = this.state.client_data
		var client = this.state.client_list.filter((e) => e.id === this.state.selected_client)
		var memo = this.state.memo
		console.log(client[0])
		this.createTransactions(value,client[0],memo)
		var balance = parseFloat(client[0].balance) + parseFloat(value.ledger_cash)
		var data = {
			client_id: client[0].id,
			balance: balance
		}
		console.log(data)
		this.updateClient(data)
		this.updateSingleImport(value)
		this.setState({selected_client: "", client_data: "", bestGuess: "", showClient: false, memo: ""})
	}

	trustTransfer(rowData,selection,trust_account){
		var that = this
  		var url = API_URL + "/api/v1/clients?account=" + trust_account
		let c = this.getClient(trust_account).then( function(res){
			let client = res.clients[0]
			console.log(client)
			that.createTransactions(rowData,client,"")
	  		var balance = parseFloat(client.balance) + parseFloat(selection.ledger_cash)
	  		var data = {
	  		client_id: client.id,
	  		balance: balance
	  	}
	  	that.updateClient(data)
	  	that.updateSingleImport(selection)
		})
	}

	removeRow(rowData,selection){
		this.updateSingleImport(selection)
		this.delete(selection)
	}

	updateImport(value){
		var url = API_URL + "/api/v1/import_files/" + value.import_file_id
		var data = {
				import_file: {
    			confirm: value.id
  			}
		}
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
			}
		});
	}

	updateSingleImport(value){
		var url = API_URL + "/api/v1/import_files/" + value.import_file_id
		var data = {
				import_file: {
    			confirm: value.id
  			}
		}
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.delete(value)
			}
		});
	}

	addAllocations(){
		var selected = this.state.selected
		var transactions = selected.map((value) => {
			let match = this.import_export_link(value)
			if (match === null || match === undefined || match === ""){
			} else {
				match = match.map((e) => {
					if (e === null || e === undefined){
						e = ""
					}
					return e
				})
				console.log(match)
				value.match_data = match
				return value
			}
		})
		var filtered = transactions.filter(function (el) {
		  return el != null;
		});
		console.log(filtered)
		var url = API_URL + "/api/v1/create/transactions";
		let data = {
			transactions: filtered,
			export_file_id: this.state.export_file_id,
		}
		axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		}).then((res) => {
			if (res) {
				console.log(res)	
			}
		})
		selected.forEach(this.delete)
	}

	delete(value, index, array) {
		var array2 = [...this.state.imports]
		var records = array2[0].records
		var new_records = records.filter((val,i) =>{
			return val.id !== value.id
		} )
		array2[0].records = new_records
		this.setState({ imports: array2 })
	}

	render(){
		const dialogHeaderClient = (
			<div>
			<h1>Transfer to Client</h1>
			</div>
		);
		const dialogFooterClient = (
			<div style={{textAlign: "center"}}>
			<Button
				className="default-btn p-button-success"
				onClick={() => this.transferClient() }
				label="Transfer"
			/>
			<Button
				className="default-btn p-button-danger"
				onClick={() => this.setState({showClient: false}) }
				label="Cancel"
			/>
			</div>
		);
		return (
			<div>
  			<div className="p-col-12 deposit-info">
          <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}} />
          <div className="card relative">
            <h1>Import Files</h1>
          </div>
          <div>
            	<Dropdown name="import_file[export_file_id]" value={this.state.export_file_id} optionLabel="name" options={this.state.files} onChange={(e) => {this.setState({export_file_id: e.value})}} placeholder="Select an Export File"/>
            	<FileUpload name="import_file[file]" url={API_URL+"/api/v1/import_files"} customUpload={true} onBeforeSend={this.myUploader2} onUpload={this.get_import_files} />
          </div>
        </div>
      
        {this.state.imports && this.state.imports.length >= 1 ?
	      <div className="p-col-12 deposit-info">
          <div className="card" style={{height:"70vh"}}>
          		<React.Fragment>
          			<ContextMenu model={this.state.menu} ref={el => this.cm = el} onHide={() => this.setState({contextSelection: null})}/>
	            	<DataTable 
							value={this.state.imports[0].records}
							selectionMode="multiple"
							responsive={true}
							scrollable={true} 
							scrollHeight="100%"
							selection={this.state.selected}
							onSelectionChange={e => this.setState({selected: e.value})}
							contextMenuSelection={this.state.contextSelection} 
							onContextMenuSelectionChange={ e => this.setState({contextSelection: e.value}) }
							onContextMenu={e => this.cm.show(e.originalEvent)}
						>
							<Column selectionMode="multiple" style={{width:'3em'}}/>
							<Column field="date_traded" header="Date Traded" sortable={true} />
							<Column field="explanation" header="Explanation" sortable={true} />
							<Column field="ledger_cash" header="Transaction Amount" sortable={true} />
							<Column field="portfolio_number" header="Trust Account #" sortable={true} />
							<Column field="transaction_description" header="Type of Transaction" sortable={true} />
							<Column field="transaction_description_line2" header="Name" sortable={true} />
							<Column field="exportedamount" header="Exported Amount" body={this.exportAmountTemplate} sortable={true} />
							<Column field="exportedclient" header="Exported Client" body={this.exportClientTemplate} sortable={true} />
							<Column field="exportedcheck" header="Exported Check" body={this.exportCheckTemplate} sortable={true} />
						</DataTable>
				</React.Fragment>
	          </div>
	        </div>
	      :
	      	""
	    	}
	    	<Button className="default-btn p-button-success" onClick={() => this.addAllocations() } label="Import Selected Transactions" style={{width:"380px",float:"right"}}/>
	    	{ /* TRUELINK DIALOG */ }
			<Dialog
			header={dialogHeaderClient}
			footer={dialogFooterClient}
			visible={this.state.showClient}
			modal={true}
			width="200px"
			closable={true}
			className="new-user client-details"
			onHide={ () => this.setState({showClient: false}) }
			>
			<div className="card card-w-title">
				<div className="p-grid p-justify-center" style={{ textAlign: "center" }} >
				<div className="p-col-12">
					{this.state.bestGuess}<br/>
					<Dropdown value={this.state.selected_client} options={this.state.clients} style={{width:"380px"}} onChange={(e) => {console.log(e);this.setState({selected_client: e.value})}} filter={true} filterPlaceholder="Select Client" filterBy="account" placeholder="Select a Client"/>
				</div>
				<div>
					<label htmlFor="memo">Memo</label><br/>
					<InputText id='memo' onChange={this.handleChange}/>
				</div>
				</div>
			</div>
			</Dialog>
      </div>
      

		)
	}
}
