import React, {Component} from 'react';
import {Messages} from 'primereact/messages';
import {Message} from 'primereact/message';
// import PropTypes from 'prop-types';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dropdown} from 'primereact/dropdown';
// import {InputTextarea} from 'primereact/inputtextarea';
import {ContextMenu} from 'primereact/contextmenu';
// import {RadioButton} from 'primereact/radiobutton';
import {Checkbox} from 'primereact/checkbox';
import {formatMoney} from '../helpers/formats'

import {API_URL} from '../helpers/api_helpers'
const axios = require('axios');

export class Transactions extends Component {

	constructor(props) {
		super(props);
		this.state = {
			dataTableValue: "",
			dataTableSelection: "",
			contextSelection: null,
			showDepositModal: false,
			showPaymentModal: false,
			contextItems: [],
			selection: [],
			menu: [],
			depositMethod: [
								{label: 'Check (Non Security)', value: 'Check (Non Security)'},
								{label: 'Chips Instruction', value: 'Chips Instruction'},
								{label: 'Credit Commercial Account', value: 'Credit Commercial Account'},
								{label: 'Fed Funds', value: 'Fed Funds'},
								{label: 'Nacha Instruction', value: 'Nacha Instruction'}
			],
			dropdownDepositMethod: "Check (Non Security)",
			destinationCodeDropdown: [
								{label: "Check (Non Security)", value: 106},
								{label: "Bill Pay", value: 0},
								{label: "Special Handling", value: 117},
								{label: "Send with Invoice", value: 107},
								{label: "FedEx", value: 114},
			],
			destinationCodeSelection: 106,
			approvedBy: [
				{label: "N/A", value: false},
				{label: "Manager", value: "manager"},
				{label: "Board", value: "board"}
			],
			approvedBySelection: false,
			additionl: "",
			custom_amount: ""
		}
		this.rowClassName = this.rowClassName.bind(this);
		this.inputTextEditor = this.inputTextEditor.bind(this)
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	componentWillMount(){
		this.setState({files: this.props.files})
		this.getExportFileCategories();
		var permissions = JSON.parse(sessionStorage.permissions)

		if ( permissions.payments_role === true ) {
			var menu = [
				{label: 'Export', icon: 'pi pi-fw pi-sign-in' },
				{label: 'Edit', icon: 'pi pi-fw pi-pencil', command: (event) => this.editRow(event,this.state.contextSelection,this.props.title) },
				{separator:true },
				{label: 'Move', icon: "pi pi-angle-double-right",
					items: [
						{label: "Recurring with Deposit", command: (event) => this.movePayment(event,this.state.contextSelection,1)},
						{label: "Recurring with Invoice", command: (event) => this.movePayment(event,this.state.contextSelection,2)},
						{label: "Common Payments", command: (event) => this.movePayment(event,this.state.contextSelection,4)},
					],
				},
				{label: 'Remove',className: 'p-button-danger', icon: 'pi pi-fw pi-trash', command: (event) =>  { if (window.confirm('Are you sure you wish to remove this export?')) this.removePayment(this.state.contextSelection,event) } },
				{label: 'Delete',className: 'p-button-danger', icon: 'pi pi-fw pi-trash', command: (event) =>  { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteRow(event,this.state.contextSelection,this.props.title) } },
			]
		} else {
			var menu = []
		}
		this.setState({menu: menu})
	}

	componentDidMount(){
		
	}

	getExportFileCategories(){
		var url = API_URL + "/api/v1/export_file_categories";
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var items = [];
				var items2 = []
				var that = this;
				res.data.export_file_categories.map(function(value,key){
					if( value.name === that.props.title || (value.name === "Management Approval" && that.props.title !== "Deposits")) {
						if(value.parent_id === null) {
							var files = that.build_list(value);
							var files2 = that.build_remove_list(value)
							var item = {label: value.name, items: files}
							var item2 = {label: value.name, items: files2}
							items.push(item);
							items2.push(item2)
						}
					} else if (value.name === "Disbursements" && that.props.title !== "Deposits") {
						if(value.parent_id === null) {
							files = that.build_list(value);
							files2 = that.build_remove_list(value)
							item = {label: value.name, items: files}
							item2 = {label: value.name, items: files2}
							items.push(item);
							items2.push(item2)
						}
					}
					return null;	
				});
				var menu = this.state.menu
				if( menu[0] !== undefined){
					menu[0].items = items
				}
				if ( menu[5] !== undefined ){
					menu[5].items = items2
				}
				this.setState({menu: menu});
			}
		});
	}

	build_list(category){
		if(this.props.files){
			var list = [];
			this.props.files.forEach((file,index) => {
				if ( file.category_id === category.id){
					list.push({label: file.name, command: (event) => this.export(this.state.contextSelection,category,event), file_id: file.id})
				}
			})
		}
		return list;
	}

	build_remove_list(category){
		if(this.props.files){
			var list = [];
			this.props.files.forEach((file,index) => {
				if ( file.category_id === category.id){
					list.push({label: file.name, command: (event) => this.removePayment(this.state.contextSelection,category,event), file_id: file.id})
				}
			})
		}
		return list;
	}

	editRow(event,row,category){
		if (category==="Deposits"){
			this.setState({Amount: row.amount,CheckNumber: row.check_number, updateDeposit: true, updateDepositRow: row.id ,showDepositModal: true })
		} else {
			this.setState({
				Amount: row.amount,
				City: row.city,
				Country: row.country,
				MailingInfo: row.mailing_info,
				PayeeNameLine1: row.payee_name_line1,
				StateProvince: row.state_province,
				StreetName: row.street_name,
				ZipPostalCode: row.zip_postal_code,
				additional: row.additional,
				PayorAccount: row.payor_account,
				PayorFirstName: row.payor_first_name,
				PayorLastName: row.payor_last_name,
				SuiteNumber: row.SuiteNumber,
				description: row.description,
				AptNumber: row.AptNumber,
				AdditionalLine1: row.AdditionalLine1,
				destinationCodeSelection: row.destination_code,
				showPaymentModal: true,
				updatePayment: true,
				updatePaymentRow: row.id
			})
		}
	}

	deleteRow(event,row,category){
		if (category==="Deposits"){
			var url = API_URL + "/api/v1/deposits/" + row.id ;
			axios.delete(url,{
				headers: {
					Authorization: sessionStorage.getItem('jwtToken'),
				} 
			})
			.then(( res ) => {
				if (res) {
					console.log("Row Deleted")
				}
			});
		} else {
			url = API_URL + "/api/v1/payments/" + row.id ;
			axios.delete(url,{
				headers: {
					Authorization: sessionStorage.getItem('jwtToken'),
				} 
			})
			.then(( res ) => {
				if (res) {
					console.log("Row Deleted")
				}
			});
		}
		this.props.update();
	}

	export(content,category,event){
		console.log(content)
		var payment_type = "";
		switch(category.name){
			case "Management Approval":
				payment_type = 'approval';
				break;
			case "Disbursements":
				payment_type = 'payment';
				break;
			case "Fees":
				payment_type = 'fee';
				break;
			case "Deposits":
				payment_type = 'deposit';
				break;
			default:
				//
		}
		if (this.state.selection && this.state.selection.length == 0 || this.state.selection.length == 1 ) {
			// Single Selection
			var data = {
				export_file: {
					name: event.item.label,
					data_type: payment_type,
					connecting_object: {
						custom_amount: this.state.custom_amount,
						comment: content.description,
						object_id: content.id,
					}
				}
			}
			console.log(data)
			var file_id = event.item.file_id;
			var url = API_URL + "/api/v1/export_files/" + file_id;
			axios.put(url,data,{
				headers: {
					Authorization: sessionStorage.getItem('jwtToken'),
				} 
			})
			.then(( res ) => {
				if (res) {
					this.messages.show({severity: 'success', summary: 'SUCCESS!', detail: 'Payment exported to file!'});
					console.log("added item to export")
					console.log(res)
					// If its a Disbursment with payment type 1 or 2
					// we add it to the summary payments
					if(content){
						var url = API_URL + "/api/v1/summary_payments/"
						axios.post(url,content,{
							headers: {
								Authorization: sessionStorage.getItem('jwtToken'),
							} 
						})
						.then(( res ) => {
							if (res) {
								console.log("added item to summary payments")
							}
						});
					}

					// Then we update the client pending balance if it's not an approval file
					if(content){
						if(payment_type != 'approval' ){
							var url = API_URL + "/api/v1/clients/" + content.client_id + "/update_balance"
							data = {
								client: {
									amount: -(content.amount),
								}
							}
							axios.put(url,data,{
								headers: {
									Authorization: sessionStorage.getItem('jwtToken'),
								} 
							})
							.then(( res ) => {
								if (res) {
									console.log("client balance updated")
								}
							});
						}
					}
				}
			})
			.catch((error) =>  {
				if( this.messages ){
					this.messages.show({severity: 'error', summary: 'FAILED!', detail: 'Failed to export to file!'});
				}
			});
		} else {
			// Multiple Selections
			console.log("Multiple")
			this.state.selection.forEach((row) => {
				console.log(event)
				console.log(row)
				var data = {
					export_file: {
						name: event.item.label,
						data_type: payment_type,
						connecting_object: {
							comment: row.description,
							object_id: row.id,
						}
					}
				}
				var file_id = event.item.file_id;
				var url = API_URL + "/api/v1/export_files/" + file_id;
				axios.put(url,data,{
					headers: {
						Authorization: sessionStorage.getItem('jwtToken'),
					} 
				})
				.then(( res ) => {
					if (res) {
						this.messages.show({severity: 'success', summary: 'SUCCESS!', detail: 'Payment exported to file!'});
						console.log("added item to export")
						console.log(res)
						// If its a Disbursment with payment type 1 or 2
						// we add it to the summary payments
						if(content){
							var url = API_URL + "/api/v1/summary_payments/"
							axios.post(url,content,{
								headers: {
									Authorization: sessionStorage.getItem('jwtToken'),
								} 
							})
							.then(( res ) => {
								if (res) {
									console.log("added item to summary payments")
								}
							});
						}

						// Then we update the client pending balance if it's not an approval file
						if(content){
							if(payment_type != 'approval' ){
								var url = API_URL + "/api/v1/clients/" + content.client_id + "/update_balance"
								data = {
									client: {
										amount: -(content.amount),
									}
								}
								axios.put(url,data,{
									headers: {
										Authorization: sessionStorage.getItem('jwtToken'),
									} 
								})
								.then(( res ) => {
									if (res) {
										console.log("client balance updated")
									}
								});
							}
						}
					}
				})
				.catch((error) =>  {
					if( this.messages ){
						this.messages.show({severity: 'error', summary: 'FAILED!', detail: 'Failed to export to file!'});
					}
				});
			})
		}
		

		// this.props.update();
	}

	removePayment(content,event){
		var file_id = event.item.file_id;
		var url = API_URL + "/api/v1/export_files/" + file_id;
		axios.delete(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log("item deleted from export")
			}
		});
		this.props.update();
	}

	updatePayment(){
		var url = API_URL + "/api/v1/payments/" + this.state.updatePaymentRow;
		var data = {
		 payment: {
		   amount: this.state.Amount,
		   payee_name_line1: this.state.PayeeNameLine1,
		   destination_code: this.state.destinationCodeSelection,
		   mailing_info: this.state.MailingInfo,
		   street_name: this.state.StreetName,
		   city:this.state.City,
		   state_province: this.state.StateProvince,
		   zip_postal_code: this.state.ZipPostalCode,
		   country: "US",
		   apt_number: this.state.AptNumber,
		   suite_number: this.state.SuiteNumber,
		   additional_line1: this.state.AdditionalLine1,
		   payor_account: this.state.PayorAccount,
		   payor_first_name: this.state.PayorFirstName,
		   payor_last_name: this.state.PayorLastName,
		   payment_type: this.state.paymentType,
		   description: this.state.description,
		   additional: this.state.additional,
		 }
		}
		axios.put(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			if (res) {
				// Check for matching Summary Payment and update that too.
				var url = API_URL + "/api/v1/summary_payments?payment_id=" + res.data.payment.id
				return axios.get(url,{
					headers: {
						Authorization: sessionStorage.getItem('jwtToken'),
					} 
				})
				.then(( res ) => {
					if (res) {
						var summary_payment = data['payment']
						var url = API_URL + "/api/v1/summary_payments/" + res.data.summary_payments[0].id
						return axios.put(url,summary_payment,{
							headers: {
								Authorization: sessionStorage.getItem('jwtToken'),
							} 
						})
						.then(( res ) => {
							if (res) {
								console.log(res)
							}
						})
					}
				})
			}
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
			this.resetPayments(this.props.formName,false)
			this.setState({
				showPaymentModal: false,
				updatePayment: false,
				updatePaymentRow: null,
			});
			this.props.update()
		});
		this.props.update()
	}

	movePayment(event,row,id){
		var url = API_URL + "/api/v1/payments/" + row.id;
		var data = {
		 payment: {
		   payment_type: id,
		 }
		}
		axios.put(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.then(( res ) => {
			if (res) {
				console.log("payment moved")
			}
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
		});
		this.props.update();
	}

	addPayment(){
		// amount,payee,method,destinationcode,mailinginfo,streetname,city,stateprovince,zippostalcode,expl1,payoraccount
		this.props.addPayment(this.state.Amount,
			this.state.PayeeNameLine1,
			this.state.PaymentMethod,
			this.state.destinationCodeSelection,
			this.state.MailingInfo,
			this.state.StreetName,
			this.state.City,
			this.state.StateProvince,
			this.state.ZipPostalCode,
			"",
			this.props.paymentType,
			this.state.AptNumber,
			this.state.SuiteNumber,
			this.state.AdditionalLine1,
			this.state.description,
			this.state.additional,
			this.state.PayorAccount,
			this.state.PayorFirstName,
			this.state.PayorLastName,
		);
		this.setState({showPaymentModal: false})	
	}

	rowClassName(rowData) {
		let brand = rowData.export_files;
		var cName = ""
		if (brand.length > 0){
			cName = brand[brand.length -1].data_type === 'approval' ? {'p-highlight-a' : "a"} : {'p-highlight-b' : "b"} ;
		}
		return cName
	}

	resetPayments(form,modal){
		console.log("In Reset!")
		console.log(form)
		document.getElementById(form).reset();
		this.setState({
			Amount: "",
			City: "",
			Country: "",
			MailingInfo: "",
			PayeeNameLine1: "",
			StateProvince: "",
			StreetName: "",
			ZipPostalCode: "",
			additional: "",
			PayorAccount: "",
			PayorFirstName: "",
			PayorLastName: "",
			SuiteNumber: "",
			description: "",
			AptNumber: "",
			AdditionalLine1: "",
			showPaymentModal: modal,
			updatePayment: false,
			updatePaymentRow: false,
		});
	}

	/* Cell Editing */
  onEditorValueChange(props, value) {
    let updatedAmount = [...props.value];
    updatedAmount[props.rowIndex][props.field] = value;
    this.setState({dataTableSelection: updatedAmount, custom_amount: value});
  }
  
  inputTextEditor(props, field) {
    return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
  }



	render() {

		var permissions = JSON.parse(sessionStorage.permissions)

		const title = "Payment"

		const paymentDialogHeader = (
			<div>
				{this.state.updatePayment ? 
					<h1>Update Payment</h1>
				:
					<h1>New Payment</h1>
				}
			</div>
		);
		
		const classtyle = this.props.formName === "common" ? "common" : ""

		const paymentDialogFooter = (
			<div style={{textAlign: "center"}}>
				{this.state.updatePayment ?
					<Button
						className="default-btn p-button-success"
						onClick={() => this.updatePayment() }
						label="Update"
					/>
				:
					<Button
						className="default-btn p-button-success"
						onClick={() => this.addPayment() }
						label="Create"
					/>
				}
				<Button
					className="default-btn p-button-danger"
					onClick={() => this.setState({showPaymentModal: false}) }
					label="Cancel"
				/>
			</div>
		);

		const formName = this.props.formName;

		return (
			<div className="p-col-12 deposit-info">
				<Messages ref={(el) => this.messages = el}></Messages>
				<div className={classtyle + " card"} >
					<div className="deposit-header">
						<h1>{this.props.title}</h1>
						{ permissions.add_payment === true ?
							<button className="p-button p-button-warning default-btn" onClick={() => this.resetPayments(formName,true) }>Add {title}</button>
						:
							""
						}
					</div>
					<React.Fragment>
						<ContextMenu model={this.state.menu} ref={el => this.cm = el} onHide={() => this.setState({contextSelection: null})}/>
						
						<DataTable
							value={this.props.dataTableValue}
							selectionMode="multiple"
							resizableColumns={true}
							editable={true}
							autoLayout={true}
							selection={this.state.selection}
							onSelectionChange={event => this.setState({selection: event.value})}
							contextMenuSelection={this.state.contextSelection} 
							onContextMenuSelectionChange={ e => this.setState({contextSelection: e.value}) }
							onContextMenu={e => this.cm.show(e.originalEvent)}
						>
							<Column selectionMode="multiple" style={{width:'2em'}}/>
							{ permissions.restrict_one_time_change === true ?
								<Column field="amount" header="Amount" editor={this.inputTextEditor} sortable={false} />
							:
								<Column field="amount" header="Amount" sortable={false} />
							}
							<Column field="payee_name_line1" header="Payee Name" sortable={false} />
							<Column field="destination_code" header="Destination Code" sortable={false} />
							<Column field="mailing_info" header="Mailing Info" sortable={false} />
							<Column field="street_name" header="Street Name" sortable={false} />
							<Column field="city" header="City" sortable={false} />
							<Column field="state_province" header="State/Province" sortable={false} />
							<Column field="zip_postal_code" header="Zip/Postal Code" sortable={false} />
							<Column field="description" header="Description" sortable={false} />
						</DataTable>
					</React.Fragment>
				</div>
				
				{ /* PAYMENT DIALOG */ }
				<Dialog
					header={paymentDialogHeader}
					footer={paymentDialogFooter}
					visible={this.state.showPaymentModal}
					modal={true}
					width="200px"
					closable={true}
					className="new-user"
					onHide={ () => this.setState({showPaymentModal: false}) }
				>
					<div className="card card-w-title">
						<div
							className="p-grid p-justify-center"
							style={{ textAlign: "center" }}
						>
							<div className="p-col-12">
								<div>
									<form id={formName}>
										<InputText id="Amount" onChange={this.handleChange} value={this.state.Amount ? this.state.Amount : "" } placeholder="Amount" />
										<InputText id="PayeeNameLine1" onChange={this.handleChange} value={this.state.PayeeNameLine1 ? this.state.PayeeNameLine1 : "" } placeholder="Payee Name" />
										<InputText id="MailingInfo" onChange={this.handleChange} value={this.state.MailingInfo ? this.state.MailingInfo : "" } placeholder="Mailing Info" />
										<InputText id="StreetName" onChange={this.handleChange} value={this.state.StreetName ? this.state.StreetName : "" } placeholder="Street Name" />
										<InputText id="AptNumber" onChange={this.handleChange} value={this.state.AptNumber ? this.state.AptNumber : "" } placeholder="Apartment Number" />
										<InputText id="SuiteNumber" onChange={this.handleChange} value={this.state.SuiteNumber ? this.state.SuiteNumber : "" } placeholder="Suite Number" />
										<InputText id="AdditionalLine1" onChange={this.handleChange} value={this.state.AdditionalLine1 ? this.state.AdditionalLine1 : "" } placeholder="Additional Line 1" />
										<InputText id="City" onChange={this.handleChange} value={this.state.City ? this.state.City : "" } placeholder="City" />
										<InputText id="StateProvince" onChange={this.handleChange} value={this.state.StateProvince ? this.state.StateProvince : "" } placeholder="State/Province" />
										<InputText id="ZipPostalCode" onChange={this.handleChange} value={this.state.ZipPostalCode ? this.state.ZipPostalCode : "" } placeholder="Zip/Postal Code" />
										<InputText id="Country" onChange={this.handleChange} value={this.state.Country ? this.state.Country : "" } placeholder="Country" />
										<InputText id="description" onChange={this.handleChange} value={this.state.description ? this.state.description : "" } placeholder="Description" />
										<Dropdown id="PaymentMethod" options={this.state.destinationCodeDropdown} value={this.state.destinationCodeSelection} onChange={event => this.setState({destinationCodeSelection: event.value})} placeholder="Destination Code" autoWidth={false} style={{width:"100%"}} />
										{this.state.destinationCodeSelection === 0 ?
										<React.Fragment>
											<InputText id="PayorAccount" onChange={this.handleChange} value={this.state.PayorAccount ? this.state.PayorAccount : "" } placeholder="Payor Account" />
											<InputText id="PayorFirstName" onChange={this.handleChange} value={this.state.PayorFirstName ? this.state.PayorFirstName : "" } placeholder="Payor First Name" />
											<InputText id="PayorLastName" onChange={this.handleChange} value={this.state.PayorLastName ? this.state.PayorLastName : "" } placeholder="Payory Last Name" />
										</React.Fragment>
										:
										""
										}
										<div className="p-col-12" style={{marginTop: "10px"}}>
											Recurring? 
											<Checkbox style={{marginLeft: "10px"}} checked={this.state.recurringPayment} onChange={e => this.setState({recurringPayment: e.checked})} />
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		)
	}
}