import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import {Password} from 'primereact/password';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import {API_URL} from '../../helpers/api_helpers';
import './login.css';

const axios = require('axios');

export class Login extends Component {
  constructor() {
    super();
    this.state = {
      display: true,
      email: "",
      password: ""
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  login() {
    // const history = this.props.history;
    var url = API_URL + "/api/v1/auth/login";
    axios.post(url, {
      email: this.state.email,
      password: this.state.password
    })
    .then(( res ) => {
      console.log(res);
      sessionStorage.setItem('jwtToken', res.data.access_token);
      sessionStorage.setItem('role', res.data.role);
      sessionStorage.setItem('name', res.data.name);
      sessionStorage.setItem('permissions', res.data.permissions)
      window.location = "/"
    })
    .catch( ( error ) => {
      console.log(error);
    })
    .then( ( res ) => {
      // always executed
    });
  }

  render() {

    const dialogHeader = (
      <div>
        <img
          className="profile-img"
          src="/assets/layout/images/loginicon.png"
          alt="User Profile"
        />
      </div>
    );

    return (
      <Dialog
        header={dialogHeader}
        visible={this.state.display}
        modal={true}
        width="400px"
        closable={false}
        className="login"
        onHide={() => this.setState({ display: false })}
      >
        <div className="card card-w-title">
          <div
            className="p-grid p-justify-center"
            style={{ textAlign: "center" }}
          >
            <div className="dialog-sign-in">
              Sign In
            </div>
            <div className="p-col-12">
              <div className="p-inputgroup">
                  <span className="p-inputgroup-addon"><i className="pi pi-user" /></span>
                  <InputText id="email" onChange={this.handleChange}  />
              </div>
            </div>
            <div className="p-col-12">
              <div className="p-inputgroup">
                  <span className="p-inputgroup-addon"><i className="pi pi-lock" /></span>
                  <Password 
                    id="password" 
                    onChange={this.handleChange} 
                    feedback={false}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                          this.login()
                      }
                    }}
                   />
              </div>
              
            </div>
            <div className="p-col-12">
              Forgot Password? Click Here
            </div>
            <div className="p-col-12">
              <Button
                className="default-btn"
                onClick={() => this.login() }
                label="SIGN IN"
                style={{ backgroundColor: "#10497f" }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
