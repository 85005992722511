import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import {Column} from 'primereact/column';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Growl} from 'primereact/growl';
import {FileUpload} from 'primereact/fileupload';
import {Checkbox} from 'primereact/checkbox';
import {Calendar} from 'primereact/calendar';

import {API_URL} from '../../helpers/api_helpers'
import {formatMoney} from '../../helpers/formats'
const axios = require('axios');

export class Allocation extends Component {
	static propTypes = {
		trust: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			dataTableValue: [],
			dataTableSelection: null,
			trust: 1,
			trust_balance: 0,
			disabled: true,
			selectedFile: null,
			trustSelectItems: [
				{label: 'Trust 1', value: '1'},
				{label: 'Over 65', value: '2'},
				{label: 'Friends & Family', value: '3'},
			],
		}
		this.fileInputRef = React.createRef()
		this.export = this.export.bind(this)
		this.sendRequest = this.sendRequest.bind(this)
		this.actionTemplate = this.actionTemplate.bind(this)
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	componentWillMount(){
		var permissions = JSON.parse(sessionStorage.permissions)
		if ( permissions.allocations === false || permissions.allocations === undefined ){
			window.location = "/"
		}
	}

	componentDidMount(){
	}

	export() {
		this.dt.exportCSV();
	}

	getTrust(trust_id){
		var url = API_URL + "/api/v1/trusts";
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				var trust = ""
				res.data.trusts.forEach((e) => {
					if (e.id === parseFloat(trust_id)){
						trust = e
					}
				})
				this.setState({trust_balance: trust.balance });
			}
		});
	}

	getClients(trust_id){
		this.setState({trust_id: trust_id})
		var status = 'active'
		var url = API_URL + "/api/v1/clients?trust_id=" + trust_id + "&status=" + status + "&balance=0";
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.getTrust(trust_id)
				this.setState({dataTableValue: res.data.clients })
			}
		});
	}

	addAllocations(){
		if ( this.state.intrest === undefined || this.state.fee === undefined || this.state.allocation === undefined || this.state.adate === undefined){
			this.growl.show({severity: 'error', summary: 'Validation Failed', detail: 'All fields must be filled out.'})
		}
		var url = API_URL + "/api/v1/allocations";
		let data = {
			trust_balance: this.state.trust_balance,
			intrest: this.state.intrest,
			fee: this.state.fee,
			allocation: this.state.allocation,
			transaction_type: "General Journal",
			transaction_date: new Date(),
			clients: this.state.selected,
			date: this.state.adate,
		}
		return axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log(res)
				if ( res.data.message === "Allocations Generated Successfully" ){
					this.setState({dataTableValue: JSON.parse(res.data.allocations), disabled: false })
					this.growl.show({severity: 'success', summary: 'Success', detail: res.data.message});
				} else {
					this.growl.show({severity: 'error', summary: 'Error', detail: res.data.message});
				}
			}
		});
	}

	actionTemplate(rowData, column) {
		if ( Math.abs(parseFloat(rowData['difference'])) > 0.25 ) {
			return <span style={{color: "red"}}>{rowData['difference']}</span>;
		} else {
			return rowData['difference']
		}
	}

	addTransaction(){
		var url = API_URL + "/api/v1/allocations/generate";
		let data = {
			intrest: this.state.intrest,
			fee: this.state.fee,
			allocation: this.state.allocation,
			transaction_type: "General Journal",
			transaction_date: new Date(),
			clients: this.state.selected,
			estimated: this.state.estimated,
			date: this.state.adate,
		}
		return axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log(res)
				if ( res.data.message === "Allocations Generated Successfully" ){
					this.setState({dataTableValue: JSON.parse(res.data.allocations), disabled: false })
					this.growl.show({severity: 'success', summary: 'Success', detail: res.data.message});
				} else {
					this.growl.show({severity: 'error', summary: 'Error', detail: res.data.message});
				}
			}
		});
	}

	deleteAllocations(){
		if ( this.state.date == "" || this.state.date == undefined ){
			this.growl.show({severity: 'error', summary: 'Error', detail: "Please select a month"})
			return
		}
		if ( this.state.trust_id == "" || this.state.trust_id == undefined ){
			this.growl.show({severity: 'error', summary: 'Error', detail: "Please select a trust"})
			return
		}
		var url = API_URL + "/api/v1/allocations/remove";
		let data = {
			trust: this.state.trust_id,
			date: this.state.date,
		}
		return axios.post(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log(res)
				if ( res.data.message === "Allocations Deleted Successfully" ){
					this.growl.show({severity: 'success', summary: 'Success', detail: res.data.message});
				} else {
					this.growl.show({severity: 'error', summary: 'Error', detail: res.data.message});
				}
			}
		});
	}

	addBankFile(){
		this.sendRequest(this.state.selectedFile)
	}

	onFilesAdded=event=>{
		this.setState({
			selectedFile: event.target.files[0],
		})
	}

	sendRequest(file) {
		const formData = new FormData();
		formData.append("uploaded_file", file, file.name);

		axios.post(API_URL + '/api/v1/allocations/upload',formData,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log("Updated Bank Records")
				console.log(res)
				this.setState({dataTableValue: JSON.parse(res.data.allocations)})
			}
		});
	}

	render() {
		var header = <div style={{textAlign:'left'}}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></div>;
		
		return (
			<div className="p-grid">
				<div className="p-col-12">
					<div className="card" style={{minHeight:"130px"}}>
						<h1>Monthly Allocations</h1>
						<Dropdown style={{height:"35px"}}value={this.state.trust_id} options={this.state.trustSelectItems} onChange={(e) => this.getClients(e.value)} placeholder="Select a Trust"/>&nbsp;
						<InputText id="trust_balance" onChange={this.handleChange} placeholder="Trust Balance" style={{width:"180px",height:"35px",marginLeft:"5px"}}/>
						<InputText id="allocation" onChange={this.handleChange} placeholder="Allocation" style={{width:"180px",height:"35px",marginLeft:"5px"}}/>
						<InputText id="fee" onChange={this.handleChange} placeholder="Fee" style={{width:"180px",height:"35px",marginLeft:"5px"}}/>
						<InputText id="intrest" onChange={this.handleChange} placeholder="Interest" style={{width:"180px",height:"35px",marginLeft:"5px"}}/>
						<Calendar value={this.state.adate} onChange={(e) => this.setState({adate: e.value})} view="month" dateFormat="mm/yy" style={{width:"180px",height:"35px",marginLeft:"5px"}} yearNavigator={true} yearRange="2010:2030" placeholder="Month"/>
						<Button className="default-btn p-button-success" onClick={() => this.addBankFile() } label="Add a Bank File" style={{width:"380px",float:"right",display:"block"}}  disabled={this.state.disabled}/>
						<Button className="default-btn p-button-success" onClick={() => this.addAllocations() } label="Preview Allocations" style={{width:"380px",float:"right",display:"block",marginRight:"10px"}} />
						<input
							ref={this.fileInputRef}
							type="file"
							label="Add a Bank File"
							onChange={this.onFilesAdded}
						/>
					</div>
				</div>
				<div className="p-col-12">
					<div className="card" style={{height:"80vh"}}>
						<h1>Clients</h1>
						<Growl ref={(el) => this.growl = el} />
						<DataTable 
							value={this.state.dataTableValue}
							selectionMode="multiple"
							responsive={true}
							scrollable={true} 
							scrollHeight="90%"
							selection={this.state.selected}
							onSelectionChange={e => this.setState({selected: e.value})}
							header={header}
							ref={(el) => { this.dt = el; }}
						>
							<Column selectionMode="multiple" style={{width:'3em'}}/>
							<Column field="account" header="Account #" sortable={true} />
							<Column field="fullname" header="Full Name" sortable={true} />
							<Column field="beginning_balance" header="Beginning Balance" sortable={true} style={{textAlign:'right'}} />
							<Column field="fee" header="Key Bank Fees" sortable={true} style={{textAlign:'right'}} />
							<Column field="intrest" header="Interest/Dividends" sortable={true} style={{textAlign:'right'}} />
							<Column field="value" header="Change in Market Value" sortable={true} style={{textAlign:'right'}} />
							<Column field="end_balance" header="Balance" sortable={true} style={{textAlign:'right'}} />
							<Column field="estimated_balance" header="Estimated Balance" sortable={true} style={{textAlign:'right'}}/>
							<Column field="bank_balance" header="Bank Balance" sortable={true} style={{textAlign:'right'}}/>
							<Column field="difference" body={this.actionTemplate} header="Balance Difference" sortable={true} style={{textAlign:'right'}}/>
						</DataTable>
					</div>
					<Button className="default-btn p-button-success" onClick={() => this.deleteAllocations() } label="Delete Allocations" style={{width:"380px",float:"left",display:"block",marginRight:"10px"}} />
					<Calendar value={this.state.date} onChange={(e) => this.setState({date: e.value})} view="month" dateFormat="mm/yy" yearNavigator={true} yearRange="2010:2030" placeholder="Month to Delete"/>
					<Button className="default-btn p-button-success" onClick={() => this.addTransaction() } label="Generate Allocations" style={{width:"380px",float:"right",display:"block",marginRight:"10px"}} />
				</div>
			</div>
		);
	}
}
// <div className="p-col-12">
// 						<Checkbox inputId="cb1" value={this.state.estimated} onChange={e => this.setState({estimated: e.checked})} checked={this.state.estimated}></Checkbox>
// 						<label htmlFor="cb1" className="p-checkbox-label">Estimated</label>
// 					</div>