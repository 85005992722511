import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {Growl} from 'primereact/growl';
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

import {API_URL} from '../helpers/api_helpers'
const axios = require('axios');

export class ClientApproval extends Component {
	static propTypes = {
    trust: PropTypes.object,
  };

	constructor(props) {
		super(props);
		this.state = {
			displayEdit: false,
			account: "",
			balance: 0.00,
			documents_scanned: false,
			documents_approved: false,
			manager_approved: false,
			board_approved: false,
		}
	}

	handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

	handleAppoval = event => {
		if ( event.target.id === "documents_scanned" ){
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	    this.updateClient({[event.target.id]: event.target.value})
		}

		if ( event.target.id === "documents_approved" && this.props.client.documents_scanned === true ){
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	    this.updateClient({[event.target.id]: event.target.value})
		}

		if ( event.target.id === "manager_approved" && this.props.client.documents_scanned === true && this.props.client.documents_approved === true ){
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	    this.updateClient({[event.target.id]: event.target.value})
		}


		if ( event.target.id === "board_approved" && this.props.client.documents_scanned === true && this.props.client.documents_approved === true ){
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	    this.updateClient({[event.target.id]: event.target.value})
		}
  };

  handleFinalAppoval = event => {
  	if (this.props.client.documents_scanned === true && this.props.client.documents_approved === true && this.props.client.board_approved === true){
  		this.setState({
	      [event.target.id]: event.target.value
	    });
	    this.updateFinalClient({ client: { status_event: 'activate', balance: 0.0, final_approval: true, case_opened: new Date() } });
  	}
  };

  updateClient(data){
		var url = API_URL + "/api/v1/clients/" + this.props.client.id ;
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				let msg = {severity: 'success', summary: 'Success Message', detail: 'Client Updated'};
    		this.growl.show(msg);
			}
		});
	}

	updateFinalClient(data){
		var url = API_URL + "/api/v1/clients/" + this.props.client.id ;
		data.id = this.props.client.id;
		data.case_opened = new Date()
		data.balance = 0.0
		console.log(data);
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				let msg = {severity: 'success', summary: 'Success Message', detail: 'Client Updated'};
    		this.growl.show(msg);
    		// and after timeout
		    window.setTimeout(() => {
		       this.props.history.push("/"+this.props.client.trust.name.replace(" ","-").toLowerCase())
		       // history is available by design in this.props when using react-router
		    }, 1000) // 1000 means 3 seconds
			}
		});
	}

	render(){
		var permissions = JSON.parse(sessionStorage.permissions)
		var ds = ""
		var dst = ""
		if (this.props.client.documents_scanned || this.state.documents_scanned) {
			ds = "p-button-success"
			dst = "Approved"
		} else {
			ds = "p-button-danger"
			dst = "Approve!"
		}
		var da = ""
		var dat = ""
		if (this.props.client.documents_approved  || this.state.documents_approved) {
			da = "p-button-success"
			dat = "Approved"
		} else {
			da = "p-button-danger"
			dat = "Approve!"
		}
		var ba = ""
		var bat = ""
		if (this.props.client.board_approved || this.state.board_approved) {
			ba = "p-button-success"
			bat = "Approved"
		} else {
			ba = "p-button-danger"
			bat = "Approve!"
		}
		var fa = ""
		var fat = ""
		if (this.props.client.final_approval || this.state.final_approval) {
			fa = "p-button-success"
			fat = "Opened"
		} else {
			fa = "p-button-danger"
			fat = "Open Account"
		}

		const dialogHeaderEdit = (
      <div>
        <h1>Add Account Number & Initial Deposit</h1>
      </div>
    );
    
    const dialogFooterEdit = (
      <div style={{textAlign: "center"}}>
        <Button
          className="default-btn p-button-success"
          onClick={this.handleFinalAppoval}
          label="Update"
        />
        <Button
          className="default-btn p-button-danger"
          onClick={() => this.setState({displayEdit: false}) }
          label="Cancel"
        />
      </div>
    );

		return(
			<div className="p-col-12 deposit-info">
				<Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}} />
				<div className="card relative">
					<h1>Account Approval</h1>
					<div className="p-grid">
						<div className="p-col-3">
							<h2 className="center-text">Documents Scanned</h2>
						</div>
						<div className="p-col-3">
							<h2 className="center-text">Documents Approved</h2>
						</div>
						<div className="p-col-3">
							<h2 className="center-text">Board Approved</h2>
						</div>
						<div className="p-col-3">
							<h2 className="center-text">Add Deposit & Open</h2>
						</div>
						{ permissions.scan_documents === true ?
							<div className="p-col-3">
								<button id="documents_scanned" value="true" className={ds + " p-button default-btn"} onClick={this.handleAppoval}>{dst}</button>
							</div>
						:
							<div className="p-col-3">
								<button id="documents_scanned" value="true" className={" p-button default-btn p-button-secondary"} disabled>{dst}</button>
							</div>
						}
						{ permissions.approve_documents === true ?
							<div className="p-col-3">
								<button id="documents_approved" value="true" className={da + " p-button default-btn"} onClick={this.handleAppoval}>{dat}</button>
							</div>
						:
							<div className="p-col-3">
								<button id="documents_approved" value="true" className={" p-button default-btn p-button-secondary"} disabled>{dat}</button>
							</div>
						}
						{ permissions.approve_for_board === true ?
							<div className="p-col-3">
								<button id="board_approved" value="true" className={ba + " p-button default-btn"} onClick={this.handleAppoval}>{bat}</button>
							</div>
						: 
							<div className="p-col-3">
								<button id="board_approved" value="true" className={"p-button default-btn p-button-secondary"} disabled>{bat}</button>
							</div>
						}
						{ permissions.final_approval === true ?
							<div className="p-col-3">
								<button id="final_approval" value="true" className={fa + " p-button default-btn"} onClick={() => this.setState({displayEdit: true})}>{fat}</button>
							</div>
						:
							<div className="p-col-3">
								<button id="final_approval" value="true" className={"p-button default-btn p-button-secondary"} disabled>{fat}</button>
							</div>
						}
					</div>
				</div>
				{ /* EDIT DIALOG */ }
				<Dialog
	        header={dialogHeaderEdit}
	        footer={dialogFooterEdit}
	        visible={this.state.displayEdit}
	        modal={true}
	        width="200px"
	        closable={true}
	        className="new-user"
	        onHide={ () => this.setState({displayEdit: false}) }
	      >
	        <div className="card card-w-title">
	          <div className="p-grid p-justify-center" style={{ textAlign: "center" }} >
	            <div className="p-col-12">
	              <div className="p-inputgroup">
                  <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span>
                  <InputText id="balance" keyfilter='num' onChange={this.handleChange} placeholder="Balance" value={this.state.balance}/>
	              </div>
	            </div>
	          </div>
	        </div>
	      </Dialog>
			</div>
		)
	}
}