import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dialog} from "primereact/dialog";
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';

import {API_URL} from '../helpers/api_helpers'
const axios = require('axios');


export class AdditionalNotes extends Component {
	static propTypes = {
		trust: PropTypes.object,
	}

	constructor(props) {
		super(props);
		this.state = {
			notes: [],
		}
		this.getClientNotes = this.getClientNotes.bind(this);
		this.removeNote = this.removeNote.bind(this);
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		})
	}

	UNSAFE_componentWillMount(){
		this.getClientNotes();
	}

	getClientNotes(){
		var url = API_URL + "/api/v1/additional_notes?client_id=" + this.props.client_id;
		axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				if (res.data.length === 0) {
					this.setState({notes: [] });
				} else {
					this.setState({notes: res.data.additional_notes });	
				}
			}
		});
	}

	editNote(id,note){
		console.log(id)
		console.log(note)
		this.setState({displayNotes: true, current_note: note, current_id: id})
	}

	updateNote(){
		var url = API_URL + "/api/v1/additional_notes/" + this.state.current_id

		var data = {additional_note: {
			note: this.state.current_note
		}}
		axios.put(url, data, {
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			}
		})
		.catch( ( error ) => {
			console.log(error);
		})
		.then( ( res ) => {
			// always executed
			this.setState({displayNotes: false});
			this.getClientNotes();
			document.getElementById("note-form").reset();
		});
	}

	removeNote(id){
		var url = API_URL + "/api/v1/additional_notes/" + id;
		axios.delete(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				this.getClientNotes();
			}
		});	
	}

	buildNotes(){
		if (this.state.notes.length > 0) {
			var that = this;
			var notes = this.state.notes.map(function (key,value) {
				return (
					<div key={value} style={{ borderBottom: "1px solid #e5e5e5", marginBottom: "5px",width: "100%", minHeight: "35px"}}>
						<h2 style={{display: "inline-block"}}>
							<span style={{color: "red"}}>{key.note}</span>
						</h2>
						<button className="p-button p-button-warning default-btn" onClick={ () => that.editNote(key.id, key.note) } style={{display:"inline-block",float: "right", width: "190px", top: "-3px"}}>Edit</button>
						<button className="p-button p-button-warning default-btn" onClick={ () => that.removeNote(key.id) } style={{display:"inline-block",float: "right", width: "190px", top: "-3px"}}>Delete</button>
					</div>
				)
			});
			return notes
		}
	}

	render(){
		const dialogHeaderNote = (
			<div>
			<h1>Update a Note</h1>
			</div>
		)
		const dialogFooterNote = (
			<div style={{textAlign: "center"}}>
			<Button
				className="default-btn p-button-success"
				onClick={() => this.updateNote() }
				label="Update"
			/>
			<Button
				className="default-btn p-button-danger"
				onClick={() => this.setState({displayNotes: false}) }
				label="Cancel"
			/>
			</div>
		)
		const notes = this.buildNotes();
		return (
			<React.Fragment>
				{notes}
				{ /* NOTES DIALOG */ }
				<Dialog
			header={dialogHeaderNote}
			footer={dialogFooterNote}
			visible={this.state.displayNotes}
			modal={true}
			width="200px"
			closable={true}
			className="new-user new-note"
			onHide={ () => this.setState({displayNotes: false}) }
			>
			<div className="card card-w-title">
				<div className="p-grid p-justify-center" style={{ textAlign: "center" }} >
					<div className="p-col-12">
						<div>
								<form id="note-form">
									<label htmlFor="note">Description</label><br/>
									<InputTextarea id='current_note' rows={5} value={this.state.current_note} onChange={this.handleChange} required={true} autoResize={true}/>
								</form>
						</div>
					</div>
				</div>
			</div>
			</Dialog>
			</React.Fragment>
		);
	}
}
