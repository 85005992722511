import React, { Component } from 'react';
import classNames from 'classnames';
import icon from "./assets/loginicon.png"
export class AppInlineProfile extends Component {

		constructor() {
				super();
				this.state = {
						expanded: false
				};
				this.onClick = this.onClick.bind(this);
		}

		onClick(event) {
				this.setState({expanded: !this.state.expanded});
				event.preventDefault();
		}

		render() {
				return  (
						<div className="profile">
								<div>
										<img src={icon} alt="" />
								</div>
								<button className="p-link profile-link" onClick={this.onClick}>
										<span className="username">{this.props.username}</span>
										<i className="pi pi-fw pi-cog"/>
								</button>
								<ul className={classNames({'profile-expanded': this.state.expanded})}>
										<li><button className="p-link"><i className="pi pi-fw pi-user"/><span>Account</span></button></li>
										<li><button className="p-link"><i className="pi pi-fw pi-inbox"/><span>Notifications</span><span className="menuitem-badge">2</span></button></li>
										<li><button onClick={this.props.logout} className="p-link"><i className="pi pi-fw pi-power-off"/><span>Logout</span></button></li>
								</ul>
						</div>
				);
		}
}