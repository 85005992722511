import React from 'react'
import {
  Route,
  Redirect,
} from 'react-router-dom'

// We are logged in.
const auth = sessionStorage.getItem('jwtToken') ? true : false

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    auth === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
  )} />
)

export default PrivateRoute;