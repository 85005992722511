import React, {Component} from 'react';
// import {Route,Link} from 'react-router-dom';
// import classNames from 'classnames';
// import PropTypes from 'prop-types';
// import PrivateRoute from './PrivateRoute';
// import {Button} from 'primereact/button';
import {AppMenu} from '../AppMenu';
// import {Menu} from 'primereact/menu';

export class MainMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	createMenu(){
		var permissions = JSON.parse(sessionStorage.permissions)
		this.menu = [
			{
				label: 'Trusts',
				icon: 'pi pi-sitemap',
				items: [],
			}
		];
		this.props.trusts.map( (trust,key) => {
			let item = {
				label: trust.name,
				icon: 'pi pi-circle-off',
				items: [
					{
						label: "Clients",
						icon: 'pi pi-fw pi-users',
						to: "/" + trust.name.replace(" ","-").toLowerCase(),
					}
				],
			}
			if (permissions.add_new_clients === true){
				item.items.push(
					{
						label: "New Client",
						icon: 'pi pi-fw pi-user-plus',
						to: "/" + trust.name.replace(" ","-").toLowerCase() + "/new-client",
					}
				)
			}
			if (permissions.edit_pending_clients === true){
				item.items.push(
					{
						label: "Pending Clients",
						icon: 'pi pi-fw pi-user',
						to: "/" + trust.name.replace(" ","-").toLowerCase() + "/pending-clients",
					}
				)
			}
			if (permissions.view_closed === true){
				item.items.push(
					{
						label: "Closed Clients",
						icon: 'pi pi-fw pi-user-minus',
						to: "/" + trust.name.replace(" ","-").toLowerCase() + "/disabled-clients",
					}
				)
			}
			this.menu[0].items.push(item);
			return null
		});
		var import_items = []

		// if (permissions.import === true ) {
		// 	import_items.push(
		// 		{
		// 			label: "Imports",
		// 			icon: 'pi pi-fw pi-download',
		// 			to: "/imports",
		// 		}
		// 	)
		// }

		if (permissions.export === true ) {
			import_items.push(
				{
					label: "Exports",
					icon: 'pi pi-fw pi-upload',
					to: "/exports",
				},
				{
					label: "Archived",
					icon: 'pi pi-fw pi-lock',
					to: "/archived",
				},
			)
		}

		if (permissions.allocations === true ) {
			import_items.push(
				{
					label: "Monthly Allocations",
					icon: 'pi pi-fw pi-share-alt',
					to: "/allocations",
				}
			)
		}

		if (permissions.import === true || permissions.export === true || permissions.allocations === true){
			this.menu.push(
				{
					label: 'Export',
					// label: 'Import / Export',
					icon: 'pi pi-fw pi-file',
					items: import_items
				}
			)
		}
		// this.menu.push(
		// 	{
		// 		label: 'Reports',
		// 		icon: 'pi pi-fw pi-calendar',
		// 		items: [
		// 			{
		// 				label: "Reports",
		// 				icon: "pi pi-fw pi-file",
		// 				to: "reports"
		// 			},
		// 			{
		// 				label: "6 Month",
		// 				icon: "pi pi-fw pi-file",
		// 				to: "6-month-reports"
		// 			}
		// 		]
		// 	}
		// )
		if (permissions.true_link_view === true || permissions.true_link_edit === true ){
			this.menu.push(
				{
					label: 'True Link',
					icon: 'pi pi-fw pi-dollar',
					to: "/true-link-clients",
				}
			)
		}
		if(permissions.check_log_view === true || permissions.check_log_add === true ){
			this.menu.push(
				{
					label: 'Check Log',
					icon: 'pi pi-fw pi-money-bill',
					items: [
						{
							label: 'Current',
							icon: 'pi pi-circle-off',
							to: "/check-log-current",
						},
						{
							label: 'Cleared',
							icon: 'pi pi-circle-on',
							to: "/check-log-cleared",
						},
					]
				}
			)
		}
		if ( permissions.add_user === true ){
			this.menu.push(
				{
					label: 'Users',
					to: "/users",
					icon: 'pi pi-fw pi-users',
				}
			)
		}
		if ( permissions.classifictions === true ) {
			this.menu[0].items.push(
				{
					label: "Classifications",
					to: "/classifications",
					icon: 'pi pi-fw pi-star',
				}
			);
		}
	}
	
	render(){
		if (this.props.loggedIn === false){
			return ""
		} else {
			this.createMenu();
		}
		
		return (
			<React.Fragment>
        { this.props.loggedIn ? <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} /> : "" }
      </React.Fragment>
		)
	}
}