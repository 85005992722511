import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import {Column} from 'primereact/column';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Growl} from 'primereact/growl';

import {API_URL} from '../../helpers/api_helpers'
import {formatMoney} from '../../helpers/formats'
const axios = require('axios');

export class Reports extends Component {
	static propTypes = {
		trust: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
		}
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	generate(){
		var url = API_URL + "/api/v1/reports/6month"
		return axios.post(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			},
			responseType: 'arraybuffer',
		})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'file.zip'); //or any other extension
			document.body.appendChild(link);
			link.click();
		});
	}

	render() {
		return (
			<div className="p-grid">
				<div className="p-col-12">
					<div className="card" style={{minHeight:"130px"}}>
						<h1>6 Month Reports</h1>
						<Button className="default-btn p-button-success" onClick={() => this.generate() } label="Generate Report" style={{width:"380px",float:"right"}}/>
					</div>
				</div>
			</div>
		);
	}
}
