import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {ContextMenu} from 'primereact/contextmenu';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {formatMoney} from '../../helpers/formats'

import {API_URL} from '../../helpers/api_helpers'
const axios = require('axios');


export class EditTransactionsTable extends Component {
	static propTypes = {
		trust: PropTypes.object,
	};

	constructor(props) {
		super(props);
		if( this.props.location.state === undefined ) {
			window.location = "/"
		}
		this.state = {
			contextSelection: null,
			contextItems: [],
			client: this.props.location.state.client,
			menu: [
				{label: 'Edit', icon: 'pi pi-fw pi-pencil', command: (event) => this.editRow(event,this.state.contextSelection) },
				{label: 'Set Client Balance to this Transaction', icon: 'pi pi-fw pi-pencil', command: (event) => this.updateClient(event,this.state.contextSelection) },
				{separator:true },
				{label: 'Delete',className: 'p-button-danger', icon: 'pi pi-fw pi-trash', command: (event) =>  { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteRow(event,this.state.contextSelection) } },
			]
		}
		this.export = this.export.bind(this);
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		})
	}

	export() {
		this.dt.exportCSV();
	}

	getTransactions(){
		var url = API_URL + "/api/v1/transactions?client_id="+this.props.location.state.id ;
		return axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log(res)
				this.setState({transactions: res.data.transactions });
			}
		});
	}

	updateTransaction(){
		var url = API_URL + "/api/v1/transactions/"+this.state.transaction_id ;
		var data = {
			transaction_type: this.state.transaction_type, 
			memo: this.state.memo, 
			amount: this.state.amount, 
			balance: this.state.balance,  
			name: this.state.name,
		}
		return axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log(res)
				this.setState({displayEdit: false, transaction_type: "", name: "", memo: "", amount: "", balance: "" })
				this.getTransactions();
			}
		});
	}

	updateClient(event, rowData){
		// const history = this.props.history;
		var url = API_URL + "/api/v1/clients/" + this.state.client.id ;
		var data = {}
		data.balance = rowData.balance
		axios.put(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log(res)
				console.log("client updated")
				this.getClient()
			}
		});
	}

	getClient(){
		var url = API_URL + "/api/v1/clients/"+this.state.client.id ;
		return axios.get(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log(res);
				this.setState({client: res.data.client });
			}
		});
	}

	deleteRow(event,row,category){
		var url = API_URL + "/api/v1/transactions/"+row.id  ;
		console.log(row)
		axios.delete(url,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			} 
		})
		.then(( res ) => {
			if (res) {
				console.log("Row Deleted")
			}
		});
	}

	editRow(event, rowData ){
		this.setState({displayEdit: true, transaction_type: rowData.transaction_type, name: rowData.name, memo: rowData.memo, amount: rowData.amount, balance: rowData.balance, transaction_id: rowData.id })
	}

	UNSAFE_componentWillMount(){
		var permissions = JSON.parse(sessionStorage.permissions)
		if ( permissions.edit_transactions === false || permissions.edit_transactions === undefined ) {
			window.location = "/"
		}
		this.getTransactions()
	}

	amountTemplate(rowData){
		return formatMoney(rowData.amount)
	}

	balanceTemplate(rowData){
		return formatMoney(rowData.balance)
	}

	render(){

		var header = <div style={{'textAlign':'left'}}>
			<i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
			<InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50" style={{width:"unset"}}/>
			<span style={{textAlign:'right',float:'right'}}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></span>
		</div>;

		const dialogEdit = (
			<div>
				<h1>Edit Transaction</h1>
			</div>
		);

		const dialogFooter = (
			<div style={{textAlign: "center"}}>
				<Button
					className="default-btn p-button-success"
					onClick={() => this.updateTransaction() }
					label="Update"
				/>
				<Button
					className="default-btn p-button-danger"
					onClick={() => this.setState({displayEdit: false}) }
					label="Cancel"
				/>
			</div>
		);

		return (
			<div>
				<div className="p-grid">
					<div className="p-col-4 client-info">
						<div className="card">
							<h1>{this.state.client.firstname} {this.state.client.lastname}</h1>
							<p style={{whiteSpace: 'pre-line',marginTop: '0px'}}>
								{this.state.client.address1+"\n"}
								{this.state.client.address2 ? this.state.client.address2+"\n" : ""}
								{this.state.client.city}, {this.state.client.state} {this.state.client.zip}
								{"\n\n"}
								<strong>Account:</strong> {this.state.client.account+"\n"}
								<strong>Balance:</strong> {this.state.client.balance+"\n"}
							</p>
						</div>
					</div>
				</div>
					<ContextMenu className={"transactions-context-menu"} style={{zIndex: 3000}} model={this.state.menu} ref={el => this.cm = el} onHide={() => this.setState({contextSelection: null})}/>
					<DataTable
						value={this.state.transactions}
						selectionMode="single"
						scrollable={true}
						scrollHeight="80vh"
						resizableColumns={true}
						columnResizeMode="fit"
						header={header}
						globalFilter={this.state.globalFilter}
						contextMenuSelection={this.state.contextSelection} 
						onContextMenuSelectionChange={ e => this.setState({contextSelection: e.value}) }
						onContextMenu={e => this.cm.show(e.originalEvent)}
						ref={(el) => { this.dt = el; }}
					>
						<Column field="transaction_date" header="Date" sortable={true} style={{width:"10%"}} />
						<Column field="transaction_type" header="Type" sortable={true} style={{width:"10%"}} />
						<Column field="name" header="Name" sortable={true} style={{width:"30%"}} />
						<Column field="memo" header="Memo" sortable={true}  style={{width:"30%"}}/>
						<Column field="amount" header="Amount" body={this.amountTemplate} sortable={true} style={{width:"10%"}} />
						<Column field="balance" header="Balance" body={this.balanceTemplate} sortable={true} style={{width:"10%"}} />
					</DataTable>
			{ /* EDIT DIALOG */ }
			<Dialog
			header={dialogEdit}
			footer={dialogFooter}
			visible={this.state.displayEdit}
			modal={true}
			width="90%"
			closable={true}
			className="new-user client-summary-dialog"
			dismissableMask={true}
			onHide={ () => this.setState({displayEdit: false}) }
			>
			<div className="card card-w-title">
				<div className="p-grid p-justify-left">
					<div className="p-col-12">
						<div>
								<form id={'transactions-form'}>
									<InputText id="transaction_type" onChange={this.handleChange} value={this.state.transaction_type ? this.state.transaction_type : "" } placeholder="Type" />
									<InputText id="name" onChange={this.handleChange} value={this.state.name ? this.state.name : "" } placeholder="Name" />
									<InputText id="memo" onChange={this.handleChange} value={this.state.memo ? this.state.memo : "" } placeholder="Memo" />
									<InputText id="amount" onChange={this.handleChange} value={this.state.amount ? this.state.amount : "" } placeholder="Amount" />
									<InputText id="balance" onChange={this.handleChange} value={this.state.balance ? this.state.balance : "" } placeholder="Balance" />
								</form>
						</div>
					</div>
				</div>
			</div>
			</Dialog>

			</div>
		);
	}
}
