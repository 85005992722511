import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import {Column} from 'primereact/column';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Growl} from 'primereact/growl';
import {Calendar} from 'primereact/calendar';

import {API_URL} from '../../helpers/api_helpers'
import {formatMoney} from '../../helpers/formats'
const axios = require('axios');

export class AllReports extends Component {
	static propTypes = {
		trust: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
		}
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	clear_fees(){
		this.setState({monthly_data: "",total: ""})
	}

	generate_fees(){
		this.setState({monthly_data: "",total: ""})
		if(this.state.date === undefined || this.state.date === ""){
			this.growl.show({severity: 'error', summary: 'Failure', detail: 'Please Select a Month.'});
		}
		var url = API_URL + "/api/v1/monthly_reports"
		var data = {
			month: this.state.date
		}
		return axios.post(url,data,{
			headers: {
				Authorization: sessionStorage.getItem('jwtToken'),
			},
		})
		.then((res) => {
			if ( res ) {
				console.log(res)
				this.setState({monthly_data: res.data.data,total: res.data.total})
			}
		});
	}

	render() {
		return (
			<div className="p-grid">
				<Growl ref={(el) => this.growl = el} />
				<div className="p-col-12">
					<div className="card" style={{minHeight:"130px"}}>
						<h1>Reports</h1>
					</div>
				</div>
				<div className="p-col-12">
					<div className="card" style={{minHeight:"130px"}}>
						<h1>Monthly Fees: </h1><h2>Total: {this.state.total}</h2>
						Select a Month: <Calendar value={this.state.date} onChange={(e) => this.setState({date: e.value})} view="month" dateFormat="mm/yy" yearNavigator={true} yearRange="2010:2030"/>
						<Button className="p-button-success" onClick={() => this.generate_fees() } label="Generate Report" style={{width:"180px",float:"right"}}/>
						<Button className="p-button-success" onClick={() => this.clear_fees() } label="Clear" style={{width:"180px",marginRight:"10px",float:"right"}}/>
						<br/>					
						<br/>					
						<DataTable value={this.state.monthly_data}>
                <Column field="client" header="Client" />
                <Column field="account" header="Account" />
                <Column field="type" header="Type"/>
                <Column field="amount" header="Fee" />                
                <Column field="date" header="Date" />                
            </DataTable>
					</div>
				</div>
			</div>
		);
	}
}
